import React, { Component, Fragment } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Push as FaqPush } from 'imports/help/ui/components/Link';
import { withRouter } from 'next/router';

import { Push } from '/components/Link';
import { removeExperiment, cookieParser, getJobTrackRoute } from '/lib/helpers';
import { withIntl } from '/imports/core/api/useIntl';
import { withTracking } from '/imports/core/hooks/useTracking';

const HelpBridge = dynamic(() => import('/imports/landing/ui/components/HelpBridge'), {
  ssr: false,
});

@withTracking
@withIntl
@withRouter
class LandingPage extends Component {
  static propTypes = {
    userIdentified: PropTypes.bool,
    router: PropTypes.object,
    locale: PropTypes.string,
    resetTracking: PropTypes.func,
    trackEvent: PropTypes.func,
    host: PropTypes.string,
    currentUser: PropTypes.object,
  };

  static async getInitialProps(ctx) {
    const token = cookieParser(ctx).token;
    return { token };
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { userIdentified, router, locale, resetTracking, trackEvent, host } = this.props;

    if (router.query.from === 'blog') {
      trackEvent('blog_exit_hack', { host });
    }
    removeExperiment('experiment_landing_global_new');
    removeExperiment('experiment_landing_global');
    removeExperiment('experiment_landing_new_design');
    removeExperiment('experiment_v2_landing_background');
    removeExperiment('rotterdam_experiment');
    removeExperiment('landing_page_experiment');
    removeExperiment('landing_budapest_experiment');
    removeExperiment('landing_template_experiment');
    removeExperiment('landing_arrows_experiment');
    removeExperiment('landing_footer_experiment');
    removeExperiment('landing_headline_experiment');
    removeExperiment('mysmartcv_landing_experiment');
    removeExperiment('experiment_v2_landing_next_arrow');
    removeExperiment('experiment_v2_landing_cta');
    removeExperiment('experiment_v2_landing_sub_title');
    removeExperiment('experiment_v2_landing_title');
    removeExperiment('experiment_v2_landing_resume');
    removeExperiment('experiment_v2_landing_footer');
    removeExperiment('experiment_resume_nation_landing');
    removeExperiment('experiment_landing_exit_hack');
    removeExperiment('exp_back_button_hack');
    if (router?.query?.reset) {
      resetTracking();
      Push('/', locale);
    }

    const url = window.location.hostname;
    const help = url.split('.');

    if (help.includes('help') && window.location.pathname === '/builder') {
      FaqPush('/', locale);
    }

    window.onpageshow = function () {
      if (cookieParser().token && !userIdentified) {
        window.location.reload();
      }
    };
  }

  ctaClick = (target) => {
    const {
      router: { asPath },
      userIdentified,
      locale,
      trackEvent,
      host,
    } = this.props;

    const destinationV2 = userIdentified ? getJobTrackRoute(host, 'resumes').as : '/onboard/start';

    trackEvent('cta_click', { target });

    const queryString = {
      from: asPath,
    };
    Push(`/onboarding?${qs.stringify(queryString)}`, locale, destinationV2);
  };

  render() {
    const {
      userIdentified,
      currentUser,
      router: {
        query: { page },
      },
    } = this.props;

    const nestedProperties = {
      ctaClick: this.ctaClick,
      userIdentified: userIdentified,
      currentUser: currentUser,
      page: page,
    };
    return (
      <Fragment>
        <link
          href="https://fonts.googleapis.com/css?family=Google+Sans:100,300,400,500,700,900,100i,300i,400i,500i,700i,900i"
          rel="stylesheet"
        />
        <link href="https://fonts.googleapis.com/css?family=Inter" rel="stylesheet" />
        <HelpBridge {...nestedProperties} />
      </Fragment>
    );
  }
}

LandingPage.displayName = 'LandingPage';

export default LandingPage;
